import React, {useEffect, useState} from 'react'

import {Link, useLocation} from 'react-router-dom';
import cn from 'classnames';

import Header from 'components/Header';

import _ from 'lodash';

import "react-image-gallery/styles/css/image-gallery.css";
import './viewcatalogue.module.scss';
import classes from './viewcatalogue.module.scss';
import {API_URL} from "utils";
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import MainImage from "../../components/MainImage/MainImage";
import Button from "../../components/Button";
import {ButtonStyles} from "../../components/Button/Button";

const ViewCatalogue = ({fetchStaffItem, staff}) => {

    const location = useLocation();

    const [mainImage, setMainImage] = useState();

    let url = window.location.pathname + window.location.search;
    let item = _.get(staff, url + '.item', []);

    useEffect(() => {
        fetchStaffItem(location.pathname, ['staff_prev', 'rectangle_medium_16_9', 'rectangle_large_16_9']);
    }, [fetchStaffItem, location]);

    useEffect(() => {
        let item = _.get(staff, url + '.item', []);
        if (item.files && item.files.length > 0) {
            setMainImage(item.files[0]);
        }
    }, [staff, url]);


    let instruction = _.find(item.files, {type: 'document'}),
        fields = _.filter(item.fields, field => {
            return ['base', 'text_block1', 'text_block2', 'complectation'].indexOf(field.label) === -1;
        }),
        files_2g = _.filter(item.files, {linked_in: 'self_group2'}),
        files_3g = _.filter(item.files, {linked_in: 'self_group3'});
    console.log(item.files?.length);
    return (
        <div className="viewCatalogueComp catalogueComp">
            <Header location={window.location}/>
            <div className={classes.staffItem}>
                <div className={classes.backButton}>
                    {item && item.catalogue_path ?
                        <Link to={item.catalogue_path}><span>&larr;</span>Назад в каталог</Link> : null}

                </div>
                <div className={classes.gallery}>
                    <div className={classes.mainImageWrapper}>
                        {mainImage && <MainImage key={mainImage.staff_prev}
                                                 imgPreload={API_URL + mainImage.staff_prev}
                                                 imgPreview={API_URL + mainImage.rectangle_medium_16_9}
                                                 imgOriginal={API_URL + mainImage.rectangle_large_16_9}/>}
                    </div>
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={5}
                    >
                        {_.map(_.filter(item.files, {linked_in: 'self', type: 'image'}), file => {
                            return <SwiperSlide key={file.id}>
                                <div className={classes.previewImage} onClick={() => setMainImage(file)}>
                                    <img src={API_URL + file.staff_prev} alt=""/>
                                </div>
                            </SwiperSlide>;
                        })}
                        {item.files && item.files.length > 5 && <div className={classes.buttonPosition}>
                            <ButtonSlidePrev/>
                            <ButtonSlideNext/>
                        </div>}
                    </Swiper>
                </div>
                <div className={classes.name}>
                    <div className={classes.title}>{item.title}</div>
                    {instruction
                        ? (<a className="instruction_field"
                              href={API_URL + '/' + instruction.path + '/' + instruction.title}>Скачать инструкцию</a>)
                        : null}
                    <div className="base_field">{_.map(_.get(_.find(item.fields, {label: 'base'}), 'values'), value => {
                        return typeof value == 'string' ? value : value.title
                    }).join(', ')}</div>
                </div>
                <div className="catalogueComp__fields">
                    <div className="header">Характеристики</div>
                    <div className="description" dangerouslySetInnerHTML={{__html: item.content}}/>
                    <div className="fields">
                        {_.map(fields, field => {
                            return (<div className="field">
                                <div className="field__title">{field.title}</div>
                                <div className="field__value" dangerouslySetInnerHTML={{
                                    __html: _.map(field.values, value => {
                                        return typeof value == 'string' ? value : value.title
                                    }).join(', ')
                                }}/>
                            </div>);
                        })}
                    </div>
                </div>
                <div className="catalogueComp__complectation">
                    <div className="title">Комплектация</div>
                    <div className="value" dangerouslySetInnerHTML={{
                        __html: _.map(_.get(_.find(item.fields, {label: 'complectation'}), 'values'), value => {
                            return typeof value == 'string' ? value : value.title
                        }).join(', ')
                    }}/>
                </div>
                <div className="catalogueComp__contentfields">
                    <div className="text" dangerouslySetInnerHTML={{
                        __html: _.map(_.get(_.find(item.fields, {label: 'text_block1'}), 'values'), value => {
                            return typeof value == 'string' ? value : value.title
                        }).join(', ')
                    }}/>
                    <div className={cn({images: true, single: files_2g.length === 1})}>
                        {_.map(files_2g, file => {
                            return (<div><img
                                src={files_2g.length === 1 ? (API_URL + file.rectangle_medium) : (API_URL + file.staff_prev)}
                                alt={item.title}/></div>);
                        })}
                    </div>
                    <div className={cn({images: true, single: files_3g.length === 1})}>
                        {_.map(files_3g, file => {
                            return (<div><img
                                src={files_3g.length === 1 ? (API_URL + file.rectangle_medium) : (API_URL + file['staff_prev_4-3'])}
                                alt={item.title}/></div>);
                        })}
                    </div>
                    <div className="text" dangerouslySetInnerHTML={{
                        __html: _.map(_.get(_.find(item.fields, {label: 'text_block2'}), 'values'), value => {
                            return typeof value == 'string' ? value : value.title
                        }).join(', ')
                    }}/>
                </div>
            </div>
        </div>
    );
}

export default ViewCatalogue

const ButtonSlideNext = () => {
    const swiper = useSwiper();
    return <Button styles={[ButtonStyles.circle, ButtonStyles.arrowRight, ButtonStyles.semiWhite]} handler={() => {
        swiper.slideNext();
    }}/>
}

const ButtonSlidePrev = () => {
    const swiper = useSwiper();
    return <Button styles={[ButtonStyles.circle, ButtonStyles.arrowLeft, ButtonStyles.semiWhite]} handler={() => {
        swiper.slidePrev();
    }}/>
}
