// ------------------------------------
// Constants
// ------------------------------------

import {api} from 'utils';

const FEEDBACK_IS_SENDING = 'feedbackIsSending';
const FEEDBACK_IS_SEND = 'feedbackIsSend';

const SET_AJAX_ERROR = 'changeStartStatus';

const SET_SCREEN_ONMAIN ='setScreenOnMain';

const SHOW_FEEDBACK = 'ShowFeedback';

// ------------------------------------
// Actions
// ------------------------------------

export const setAjaxError = (error) => {
    return {
        type: SET_AJAX_ERROR,
        payload: error
    };
}

export const selectScreenOnMain = (screen) => {
    return {
        type: SET_SCREEN_ONMAIN,
        payload: screen
    };
}

export const setShowFeedback = (show) => {
    return {
        type: SHOW_FEEDBACK,
        payload: show
    };
}

export const feedback = (data) => {
    return (dispatch) => {
        dispatch({type: FEEDBACK_IS_SENDING});
        return api('/feedback', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res) {
                    dispatch({type: FEEDBACK_IS_SEND});
                }
            });
    };
};

export const sendRequestForInfo = (data) => {
    return (dispatch) => {
        dispatch({type: FEEDBACK_IS_SENDING});
        return api('/i-want-know-more', {
            method: 'POST',
            body: JSON.stringify(data)
        }, dispatch)
            .then(res => {
                if (res) {
                    dispatch({type: FEEDBACK_IS_SEND});
                }
            });
    };
};


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [FEEDBACK_IS_SENDING]: (state) => {
        return ({ ...state, fetching: true });
    },
    [FEEDBACK_IS_SEND]: (state) => {
        return ({ ...state, fetching: false });
    },
    [SHOW_FEEDBACK]: (state, action) => {
        return ({ ...state, showFeedback: action.payload });
    },
    [SET_SCREEN_ONMAIN]: (state, action) => {
        return ({...state, selectedScreenOnMain: action.payload });
    }
};

let initialState = {
    startStatus: true,
    selectedScreenOnMain: 'about',
    showFeedback: false
};

export default function hangarReducer(state = initialState, action) {

    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
