import React, {useCallback, useState} from 'react';
import classes from './mainimage.module.scss';
import cn from "classnames";

function MainImage({imgPreload, imgPreview, imgOriginal}) {
    const [magnifierMode, setMagnifierMode] = useState(false);
    const [cHeight, setCHeight] = useState(null);
    const [cWidth, setCWidth] = useState(null);
    const div = useCallback(node => {
        if (node !== null) {
            setCHeight(node.getBoundingClientRect().height);
            setCWidth(node.getBoundingClientRect().width);
        }
    }, []);
    //const mainImageOriginalSize = {width: 1520, height: 854}
    const [cursor, setCursor] = useState(null);
    const positionOriginal = (x, y) => {

        //return [-(x * (mainImageOriginalSize.width - cWidth) / cWidth), -(y * (mainImageOriginalSize.height - cHeight) / cHeight)];
        return [-(x * (cWidth * 2 - cWidth) / cWidth), -(y * (cHeight * 2 - cHeight) / cHeight)];
    }
    const mainImagePosition = cursor === null ? null : positionOriginal(cursor[0], cursor[1]);
    return (
        <div ref={div} className={cn({[classes.mainImage]: true, [classes.magnifierMode]: magnifierMode})}
             onMouseMove={(e) => {
                 setCursor([e.nativeEvent.offsetX, e.nativeEvent.offsetY]);
             }}
             onMouseOut={() => {
                 setCursor(null);
             }}
             onClick={() => setMagnifierMode((m) => !m)}

        >
            <img src={imgPreload} alt='' className={classes.preload}/>
            <div className={classes.preview}><img src={imgPreview} alt=''/></div>
            <div className={classes.original}
                 style={cursor !== null ? {left: mainImagePosition[0] + 'px', top: mainImagePosition[1] + 'px'} : {}}>
                <img src={imgOriginal} alt='' className={classes.original}/>
            </div>

        </div>
    );
}

export default MainImage;