import React from 'react'

import {useParams} from 'react-router-dom';
import UsefulItem from "Routes/UsefulItem";
import Useful from "Routes/Useful";

const UsefulDirect = () => {
    let params = useParams();
    const isItem = params['*'].indexOf('-item') === params['*'].length - '-item'.length;
    return isItem ? <UsefulItem /> : <Useful/>;
}

export default UsefulDirect;
