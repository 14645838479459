import React, {useEffect, useState} from 'react'

import {Link, useLocation} from 'react-router-dom';
import cn from 'classnames';

import LampItem from 'components/LampItem';

import _ from 'lodash';

import './catalogue.module.scss';
import classes from './catalogue.module.scss';

import DEFAULT_LOGO from './assets/default_logo_catalogue.png';
import {API_URL} from "utils";

const Catalogue = ({fetchStaff, propsStaff}) => {
    const location = useLocation();
    const [contentCompactView, setContentCompactView] = useState(true);
    const [stateFields, setStateFields] = useState({});

    useEffect(() => {
        setStateFields({});
        fetchStaff(location.pathname + location.search);
    }, [location, fetchStaff]);


    let fieldsString = _.map(stateFields, (field, fieldId) => {
        return 'fields[' + fieldId + ']=' + field
    }).join('&');
    let url = window.location.pathname + (fieldsString ? '?' + fieldsString : '');
    let fstaff = _.get(propsStaff, url, {}),
        staff = _.get(fstaff, 'items', []),
        catalogue = _.get(fstaff, 'catalog', {});
    let content = catalogue ? _.get(catalogue, 'content', '').split('<hr />') : null,
        children = _.get(propsStaff, url + '.children', {});
    let header = _.get(_.find(catalogue.files, {appointment: 'HEADER'}), 'catalogue_header');
    if (header) {
        header = API_URL + header;
    }
    return (
        <div className="catalogueComp">
            <div className={classes.wrapper}>
                <div className="catalogueComp__header">
                    <div className={'content'}>
                        <div className="text">
                            <div className="text__title">
                                {catalogue.title}
                            </div>
                        </div>
                    </div>
                </div>
                {header && false
                    ? (<div className="catalogueComp__header">
                        <div className="image"><img src={header} alt={catalogue.title}/></div>
                        <div className="text">
                            <div className="text__title">
                                {catalogue.title}
                            </div>
                        </div>
                    </div>)
                    : null}
                {content.length > 1
                    ? (
                        <div className={cn({
                            "catalogueComp__content": true,
                            'open': !contentCompactView
                        })}>
                            <div dangerouslySetInnerHTML={{__html: content[0]}}/>
                            <div className="rest" dangerouslySetInnerHTML={{__html: content[1]}}/>
                            <div className="trigger" onClick={() => {
                                setContentCompactView((c) => !c)
                            }}>
                                <div>{contentCompactView ? 'Читать полность' : 'Свернуть'}</div>
                            </div>
                        </div>
                    )
                    : (
                        <div className="catalogueComp__content"
                             dangerouslySetInnerHTML={{__html: catalogue.content}}/>)
                }

                {!_.isEmpty(children)
                    ? (
                        <div className="catalogueComp__sections">
                            {_.map((Object.values(children)).sort((a, b) => b.sorting - a.sorting), child => {
                                let logo = _.get(_.find(child.files, {appointment: 'LOGO'}), 'catalogue_logo');
                                if (logo) {
                                    logo = API_URL + logo;
                                } else {
                                    logo = DEFAULT_LOGO;
                                }
                                return (
                                    <Link key={child.id} to={child.path} className="section">
                                        <div className="section__title">
                                            <div>{child.title}</div>
                                        </div>
                                        <div className="section__image">
                                            <img src={logo} alt={child.title}/>
                                        </div>
                                        <div className="section__alter-title">
                                            {child.second_title || 'подробнее'}
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    )
                    : (
                        <div className="catalogueComp__staff">
                            {/*<StaffFilter
                                    fields={_.filter(catalogue.fields, field => {
                                        return ['temperature', 'type'].indexOf(field.label) !== -1;
                                    })}
                                    onChange={(fieldId, value) => {
                                        let fields = _.clone(stateFields);
                                        if (value) {
                                            fields[fieldId] = value;
                                        } else {
                                            delete fields[fieldId];
                                        }
                                        let fieldsString = _.map(fields, (field, fieldId) => {
                                            return 'fields[' + fieldId + ']=' + field
                                        }).join('&');
                                        let url = window.location.pathname + (fieldsString ? '?' + fieldsString : '');
                                        navigate(url);
                                    }}
                                />*/}
                            <div className="items">
                                {_.map(staff, item => {
                                    return (
                                        <LampItem key={item.id} item={item}/>
                                    );
                                })}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Catalogue

