import React, {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom';
import Header from 'components/Header';
import Preloader from 'components/Preloader';


import _ from 'lodash';
import moment from 'moment';

import './commonsection.module.scss'
import {API_URL} from "utils";

const CommonSection = ({fetchStaff, propsStaff, fetching}) => {
    const location = useLocation()

    useEffect(() => {
        fetchStaff(location.pathname, {schemes: 'staff_prev_4-3,staff_prev_png,staff_a4'});
    }, [location, fetchStaff]);

    let url = window.location.pathname;
    let staff = _.get(propsStaff, url + '.items', []),
        catalogue = _.get(propsStaff, url + '.catalog', {});

    return (
        <div className="commonSection">
            <Header location={window.location}/>
            <div className="wrapper">
                <div className="commonSection__items">
                    <div className="commonSection__description">
                        <div className="description">{catalogue.description}</div>
                        <div className="description" dangerouslySetInnerHTML={{__html: catalogue.content}}/>
                    </div>
                    {_.map(staff, item => {
                        return (
                            <div className={'commonSection__item ' + item.catalog_type}>
                                <div className="date">{moment(item.date).locale('ru').format('DD MMMM')}</div>
                                <div className="image">
                                    <div className="image__wrapper">
                                        <img
                                            src={API_URL + _.get(_.find(item.file, {is_main: '1'}), (item.catalog_type === 'video' ? 'staff_prev_4-3' : 'staff_a4'))}
                                            alt={item.title}
                                        />
                                    </div>
                                </div>
                                <div className="title">{item.title}</div>
                                <Link className="readon" to={'/useful/' + item.label + '-item'}/>
                            </div>);
                    })}
                </div>
                <Preloader trigger={_.some(fetching, (f) => {
                    return f;
                })}/>
            </div>
        </div>
    );
}

export default CommonSection

