import React from 'react'
import {Link} from 'react-router-dom';
import get from 'lodash.get';

import classes from './lampitem.module.scss';
import DEFAULT_LOGO from  '../LampItem/assets/default_logo.png';
import {API_URL} from "utils";

const LampItem = (props) => {

        let item = props.item;
        let image = get((item.file || []).find(f => f.is_main === '1'), 'staff_prev');
        if (image) {
            image = API_URL + image;
        } else {
            image = DEFAULT_LOGO;
        }
        return (
            <Link key={item.id} to={item.catalog_path + '/' + item.label + '-item'} className={classes.lampItem}>
                <div className={classes.title}>
                    <div>{item.title}</div>
                </div>
                <div className={classes.image}>
                    <img src={image} alt={item.title}/>
                </div>
                <div className={classes.description}>
                    <div>{item.description}</div>
                </div>
                <div className={classes.readon}>подробнее</div>
            </Link>
        );

}

export default LampItem;
