import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {RECAPTCHA_KEY} from "utils/constants";

import classes from './styles.module.scss';
import {createPortal} from "react-dom";

class JebloButton extends React.Component {

    constructor(props) {
        super(props);
        this.reCaptchaRef = React.createRef();
    }

    state = {
        feedback: null,
        feedbackForm: (this.props.location && this.props.location.pathname === '/welcome'),
        name: '',
        phone: '',
        email: '',
        city: '',
        cooperationType: 'optpartner',
        textSise: 12
    };

    componentDidMount() {
        let el = document.getElementById('jebloButton');
        if (el) {
            let sizes = el.getBoundingClientRect();
            if (sizes.width) {
                this.setState({textSize: sizes.width / (70 / 16)});
            }
        }

    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.fetching && this.props.fetching) {
            this.setState({feedback: 'sended'});
        }
    }

    changeData(e) {
        e.preventDefault();
        this.setState({[e.target.name]: e.target.value});
    }

    async sendFeedback() {
        console.log('sendFeedback');
        if ((this.state.phone || this.state.email) && this.state.name) {
            console.log('all is good');
            const token = await this.reCaptchaRef.current.executeAsync();
            console.log('token');
            console.log(token);
            this.props.sendRequestForInfo(
                {
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.email,
                    cooperationType: this.state.cooperationType,
                    city: this.state.city,
                    token: token
                });
        } else {
            console.log('bad request');
            this.setState({feedback: 'badrequest'});
        }

    }

    render() {
        return (
            <div className={classes.jebloButton} id="jebloButton">
                {this.state.feedbackForm && createPortal(<div className={classes.frm}>
                    <div className={classes.close} onClick={() => {
                        this.setState({feedback: null, feedbackForm: false});
                    }}></div>
                    {this.state.feedback !== null ? (
                            <div>
                                {this.state.feedback === 'sended'
                                    ? (<div className={classes.text}>Спасибо.<br/>Мы свяжемся с вами в ближайшее время.
                                    </div>)
                                    : (<div className={classes.text}>Введите ваши контактные данные и нажмите кнопку
                                        "Отправить".</div>)
                                }

                                <div className={classes.sendContact} onClick={(e) => {
                                    e.preventDefault();
                                    if (this.state.feedback === 'sended') {
                                        this.setState({feedback: null, feedbackForm: false});
                                    } else {
                                        this.setState({feedback: null});
                                    }

                                }}>Хорошо</div>
                            </div>
                        )
                        :
                        (
                            <div>
                                <h2>Заполните форму</h2>
                                <div className={classes.text}>И узнайте максимум информации по сотрудничеству</div>
                                <div className={classes.input + ' ' + classes.name}>
                                    <input type="text" name="name" value={this.state.name}
                                           placeholder="Ваше имя"
                                           onChange={this.changeData.bind(this)}/>
                                </div>
                                <div className={classes.input + ' ' + classes.phone}>
                                    <input type="phone" name="phone" value={this.state.phone}
                                           placeholder="Ваш телефон"
                                           onChange={this.changeData.bind(this)}/>
                                </div>
                                <div className={classes.input + ' ' + classes.email}>
                                    <input type="email" name="email" value={this.state.email}
                                           placeholder="Ваш email"
                                           onChange={this.changeData.bind(this)}/>
                                </div>
                                <div className={classes.input + ' ' + classes.city}>
                                    <input type="text" name="city" value={this.state.city}
                                           placeholder="Ваш город"
                                           onChange={this.changeData.bind(this)}/>
                                </div>
                                <div className={classes.input + ' ' + classes.cooperationType}>
                                    <select name="cooperationType" value={this.state.cooperationType}
                                            placeholder="Вид сотрудничества"
                                            onChange={this.changeData.bind(this)}>
                                        <option value="optpartner">Форма сотрудничества - оптовый партнёр</option>
                                        <option value="retailpartner">Форма сотрудничества - розничный партнёр</option>
                                    </select>
                                </div>
                                <div className={classes.sendContact} onClick={(e) => {
                                    e.preventDefault();
                                    this.sendFeedback();

                                }}>Оставить заявку</div>
                                <ReCAPTCHA
                                    sitekey={RECAPTCHA_KEY}
                                    size="invisible"
                                    ref={this.reCaptchaRef}
                                />
                            </div>
                        )
                    }

                </div>, document.body)}
                <div className={classes.btn + ' ' + (this.state.feedbackForm ? classes.onForm : '')} onClick={() => {
                    this.setState({feedbackForm: !this.state.feedbackForm});
                }}>
                    Оставить заявку
                </div>
            </div>
        )
    }
}

export default JebloButton
