import { connect } from 'react-redux'
import { actions } from 'redux/staff';

import Catalogue from './Catalogue'

const mapStateToProps = (state) => ({
    propsStaff: state.staff.staff,
    catalogueTree: state.staff.catalogueTree
});

export default connect(mapStateToProps, Object.assign({}, actions))(Catalogue)
