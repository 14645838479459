import React, {useEffect, useRef, useState} from 'react'
//Libs
import {YMInitializer} from 'react-yandex-metrika';
import {Link, Outlet} from 'react-router-dom';
import cn from 'classnames';
import classes from './coreLayout.module.scss';
import {ROUTES} from "routes";
import {useLocation, useNavigate} from 'react-router-dom'
import _ from 'lodash'
import {API_URL} from "utils";
import DEFAULT_LOGO from "./assets/default_logo_catalogue.png";
import JebloButtonV2 from "../../components/JebloButtonV2";

const CoreLayout = ({fetchStaff, propsStaff, selectScreenOnMain}) => {

    const [open, switchOpen] = useState(false);
    const [disabledHover, setDisabledHover] = useState(false)
    const dhTimer = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('scroll', function() {
            if (dhTimer.current) {
                clearTimeout(dhTimer.current);
            }
            setDisabledHover(true);
            dhTimer.current = setTimeout(function(){
                setDisabledHover(false);
            },500);
        }, false)
    }, []);

    useEffect(() => {
        fetchStaff('/catalogue');

    }, [fetchStaff]);

    const getLogoFile = (item) => {
        let logo = _.get(_.find(item.files, {appointment: 'LOGO'}), 'catalogue_logo');
        if (logo) {
            logo = API_URL + logo;
        } else {
            logo = DEFAULT_LOGO;
        }
        return logo;
    }
    const toSelectScreenOnMain = (e, screen) => {
        if (window.innerWidth >= 768) {
            e.preventDefault();
            selectScreenOnMain(screen);
            navigate(ROUTES.main);
        }
    }
    let location = useLocation();
    const isHome = location.pathname === ROUTES.main;
    const rootCatalogue = Object.values(_.get(propsStaff, '/catalogue.children', {}));
    return (
        <div className={classes.screenContainer + (isHome ? (' ' + classes.home) : '')}>
            <YMInitializer accounts={[91774907]} options={{webvisor: true}}/>

            <div className={cn({[classes.header]: true, [classes.open]: open})}>
                <div className={classes.column}>
                    <div className={cn({[classes.hamburger]: true, [classes.second]: !isHome})}
                         onClick={() => switchOpen((open) => !open)}/>
                </div>
                <Link to={'/'} className={cn({[classes.logo]: true, [classes.second]: !isHome})}/>
                <div className={classes.column}/>
                <div className={cn({[classes.menu]: true, [classes.open]: open})}
                     onClick={() => switchOpen(() => false)}>
                    <Link className={classes.link} to={'/about-brend'} onClick={(e) => toSelectScreenOnMain(e, 'about', isHome)}>О нас</Link>
                    <Link className={classes.link} to={'/catalogue'}>Каталог</Link>
                    <div className={cn({[classes.catalogueHeader]: true, [classes.disabledHover]: disabledHover})}>
                        <div className={classes.content}>
                            {rootCatalogue ? rootCatalogue.map(item => {
                                return (
                                    <Link to={item.path} className={classes.item} key={item.id}>
                                        <div className={classes.image}>
                                            <img src={getLogoFile(item)} alt=''/>
                                        </div>
                                        <div className={classes.title}>{item.title}</div>
                                    </Link>
                                );
                            }) : null}
                        </div>
                    </div>
                    <Link className={classes.link} to={'/development-map'} onClick={(e) => toSelectScreenOnMain(e, 'geography', isHome)}>География присутствия</Link>
                    <Link className={classes.link} to={'/useful'}>Полезное</Link>
                    <Link className={classes.link} to={'/contacts'}>Контакты</Link>
                    <div className={classes.spacer}/>
                    <div className={classes.socialNets}>
                        <a rel="noreferrer" href="https://www.instagram.com/hivision_russia" target="_blank" className={classes.inst}>inst</a>
                        <a rel="noreferrer" href="https://www.youtube.com/channel/UCuNSacHCCxhbvfzjddRQYiA" target="_blank"
                           className={classes.youtube}>youtube</a>
                    </div>
                </div>
            </div>
            <div className={classes.wrapper}>
                <Outlet/>
                <div className={classes.jebloWrapper}>
                    {!isHome ? <JebloButtonV2/> : null}
                </div>
            </div>
        </div>
    );
}

export default CoreLayout;
