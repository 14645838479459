import {setAjaxError} from "../redux/hangar";

export const API_URL = 'https://back.hivision.pro';
//export const API_URL = 'http://hivision-api';

export const api = (uri, data, dispatch, fileUpload = false) => {
    let opts = {
        method: 'get',
        mode: "cors",
        headers: {
            'Accept': 'application/json'
        }
    };
    if (!fileUpload) {
        opts.headers[ 'Content-Type' ] = 'application/json; charset=UTF-8';
    }
/*    let token = localStorage.getItem('token');
    if (token) {
        opts.headers[ 'X-Api-Token' ] = token;
    }*/
    opts = {...opts, ...data};
    opts.headers = new Headers(opts.headers);
    return fetch(API_URL + uri, opts).then(function (response) {
        if (response.status !== 200) {
            if (dispatch) {
                return (response.json()).then(
                    (res) => {
                        dispatch(setAjaxError({response: res, status: response.status, url: response.url}));
                        return false;
                    }
                );
            }
        } else {
            return response.json();
        }
    });
};

export const http_build_query = (formdata, numeric_prefix, arg_separator) => {
    let key, use_val, use_key, i = 0, tmp_arr = [];
    if (!arg_separator) {
        arg_separator = '&';
    }
    for (key in formdata) {
        if (formdata[key] !== undefined && formdata[key] !== null && formdata[key] !== '') {
            use_key = encodeURIComponent(key);
            use_val = encodeURIComponent((formdata[ key ].toString()));
            if (numeric_prefix && !isNaN(key)) {
                use_key = numeric_prefix + i;
            }
            tmp_arr[ i ] = use_key + '=' + use_val;
            i++;
        }
    }
    return tmp_arr.join(arg_separator);
};