export const SCREENS = {main: 'main', contacts: 'contacts', acquaint: 'acquaint'};
export const ALL_LINKS = {
    'video': 'всё видео',
    'common': 'все статьи',
    'news': 'все новости'
};
export const BUY_LINKS = {
    wildberries: 'https://www.wildberries.ru/seller/189034?sort=popular&page=1&fbrand=77911',
    ozon: 'https://www.ozon.ru/seller/1automall-30164/avtotovary-8500/hivision-83400431/?miniapp=seller_30164',
    farpost: 'https://www.farpost.ru/user/AutogarantTrade/zapchasti/+/%D0%BE%D0%BF%D1%82%D0%B8%D0%BA%D0%B0/',
    whatsapp: 'https://api.whatsapp.com/send?phone=79089801070&text=Добрый%20день%2C%20я%20по%20поводу'
}

export const RECAPTCHA_KEY = '6LcfQZwpAAAAALI75_Ky5L2GGPSaANt_jd5ooeoz';