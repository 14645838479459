export const ROUTES = {
    main: '/',
    useful: '/useful/*',
    instructions: '/instructions',
    instructionsItem: '/instructions/*',
    userManuals: '/user-manuals',
    userManualsItem: '/user-manuals/*',
    portfolio: '/portfolio',
    cooperation: '/cooperation',
    contacts: '/contacts',
    selection: '/selection',
    catalogue: '/catalogue/*',
    search: '/search',
    geography: '/development-map',
    aboutBrend: '/about-brend',
}
