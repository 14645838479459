import { connect } from 'react-redux'
import { actions } from 'redux/staff';

import Home from './Home'

const mapStateToProps = (state) => ({
    certificates: state.staff.certificates,
    staff: state.staff.staff,
    selectedScreenOnMain: state.hangar.selectedScreenOnMain
});

export default connect(mapStateToProps, actions)(Home)
