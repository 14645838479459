import { connect } from 'react-redux'
import { actions } from 'redux/staff';

import Useful from './Useful'

const mapStateToProps = (state) => ({
    propsStaff: state.staff.staff,
    fetching: state.staff.fetching
});

export default connect(mapStateToProps, Object.assign({}, actions))(Useful)
