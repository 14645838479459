import React from "react";
import PropTypes from 'prop-types';
import classes from "./button.module.scss";
import cx from 'classnames';

export const ButtonStyles = {
    small: 'small',
    blue: 'blue',
    gray: 'gray',
    lightGray: 'lightGray',
    circle: 'circle',
    arrowLeft: 'arrowLeft',
    arrowRight: 'arrowRight',
    fullWidth: 'fullWidth',
    blueBorder: 'blueBorder',
    rounded: 'rounded',
    white: 'white',
    semiWhite: 'semiWhite',
    shadow: 'shadow',
    noBorder: 'noBorder'
};

const Button = ({children, handler, styles = [], large = false}) => {
    const styleClasses = {};
    styles.forEach((style) => {
        styleClasses[classes[style]] = true;
    });
    return (
        <button className={cx({[classes.button]: true, [classes.large]: large, ...styleClasses})}
                onClick={handler}>{children}</button>
    );
}

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    handler: PropTypes.func.isRequired,
    large: PropTypes.bool,
    styles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ButtonStyles)))
}

export default Button;