import { connect } from 'react-redux'
import { actions } from 'redux/staff';

import ViewCatalogue from './ViewCatalogue'

const mapStateToProps = (state) => ({
    staff: state.staff.detailedStaff,
    catalogueTree: state.staff.catalogueTree
});

export default connect(mapStateToProps, Object.assign({}, actions))(ViewCatalogue)
