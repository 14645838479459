import React from 'react'

import {useParams} from 'react-router-dom';
import ViewCatalogue from "Routes/ViewCatalogue";
import Catalogue from "Routes/Catalogue";

const CatalogueDirect = () => {
    let params = useParams();
    const isItem = params['*'].indexOf('-item') === params['*'].length - '-item'.length;
    return isItem ? <ViewCatalogue /> : <Catalogue/>;
}

export default CatalogueDirect;
