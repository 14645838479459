import React from 'react'
import {Link} from 'react-router-dom';
import Header from 'components/Header';
import _ from 'lodash';
import moment from 'moment';
import classes from './contacts.module.scss';
import {API_URL} from "utils";
import {ALL_LINKS} from "../../utils/constants";

class Contacts extends React.Component {

    componentDidMount() {
        this.props.fetchStaff(window.location.pathname, {schemes: 'staff_prev_4-3,staff_prev_png,staff_a4'});
    }

    render() {
        let url = window.location.pathname;
        let staff = _.get(this.props.staff, url + '.items', []),
            catalogue = _.get(this.props.staff, url + '.catalog', {});
        return (
            <div className={classes.contactsComp}>
                <Header location={window.location}/>
                <div className={classes.items}>
                    <div className={classes.description}>
                        <div className={classes.description}>{catalogue.description}</div>
                        <div className={classes.content} dangerouslySetInnerHTML={{__html: catalogue.content}}/>
                    </div>
                    {_.map(staff, item => {
                        return (
                            <div className={classes.item}>
                                <div className={classes.date}>{moment(item.date).locale('ru').format('DD MMMM')}</div>
                                <div className={classes.image}>
                                    <div className={classes.wrapper}>
                                        <img
                                            src={API_URL + _.get(_.find(item.file, {is_main: '1'}), (item.catalog_type === 'video' ? 'staff_prev_4-3' : 'staff_a4'))} alt={item.title}/>
                                    </div>
                                </div>
                                <div className={classes.title}>{item.title}</div>
                                <Link  className={classes.readon} to={'/contacts/' + item.label + '-item'}/>
                                {item.second_title !== ''
                                    ?
                                    (<div className={classes.secondTitle}>{item.second_title}</div>)
                                    :
                                    (<div className={classes.secondTitle + ' ' + classes.links}>{ALL_LINKS[item.catalog_type]}</div>)
                                }
                            </div>);
                    })}
                </div>
            </div>
        );
    }
}

export default Contacts

