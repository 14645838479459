import React from 'react'

import _ from 'lodash';
import cn from 'classnames';
import classes from './home.module.scss'
import MobileScreen from "./MobileScreen";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import JebloButton from "../../components/JebloButtonV2";

class Home extends React.Component {
    state = {
        selectedScreen: 1,
        search: '',
        lightbox: null
    };

    componentDidMount() {
        this.props.fetchStaff('/development-map');
        this.props.fetchStaff('/development-map/cities');
        this.props.fetchStaff('/about-brend-for-main');
    }

    selectScreen(screen, e) {
        e.preventDefault();
        if (this.state.selectedScreen !== screen) {
            this.setState({selectedScreen: screen});
        }
    }

    render() {
        let fstaff = _.get(this.props.staff, '/development-map', {}),
            geography = _.get(fstaff, 'catalog', {}),
            bstaff = _.get(this.props.staff, '/about-brend-for-main', {}),
            aboutBrend = _.get(bstaff, 'catalog', {})
        ;
        return (
            <div className={cn({
                [classes.homeScreens]: true
            })}>
                <div className={classes.mobileSwiper}>
                    <Swiper

                        spaceBetween={0}
                        slidesPerView={1}
                    >
                        <SwiperSlide><MobileScreen screenVariant={'first'}/></SwiperSlide>
                        <SwiperSlide><MobileScreen screenVariant={'second'}/></SwiperSlide>
                        <SwiperSlide><MobileScreen screenVariant={'third'}/></SwiperSlide>
                    </Swiper>
                    <div className={classes.mobileText}>Свет для вашего автомобиля</div>
                    <JebloButton/>
                </div>
                <div className={cn({
                    [classes.screens]: true,
                    [classes['select' + (this.state.selectedScreen ? this.state.selectedScreen : '')]]: true
                })}>
                    <div className={cn({
                        [classes.screen]: true,
                        [classes.geography]: true,
                        [classes.selected]: this.props.selectedScreenOnMain === 'geography'
                    })}
                         onClick={this.selectScreen.bind(this, 2)}>
                        <div className={classes.wrapper}>
                            <div className={classes.info}>
                                <div className={classes.text}
                                     dangerouslySetInnerHTML={{__html: _.get(geography, 'content', 'загружается...')}}/>
                            </div>
                        </div>
                    </div>
                    <div className={cn({
                        [classes.screen]: true,
                        [classes.about]: true,
                        [classes.selected]: this.props.selectedScreenOnMain === 'about'
                    })}
                         onClick={this.selectScreen.bind(this, 1)}>
                        <div className={classes.wrapper}>
                            <div className={classes.info}>
                                <div className={classes.titles}>
                                    <div className={classes.title}>Бренд</div>
                                    <div className={classes.title}>№1 на Дальнем Востоке</div>
                                </div>

                                <div className={classes.text}
                                     dangerouslySetInnerHTML={{__html: _.get(aboutBrend, 'content', 'загружается...')}}/>
                                <JebloButton/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Home

