import { connect } from 'react-redux';
import { actions } from 'redux/staff';
import {selectScreenOnMain} from 'redux/hangar'

import CoreLayout from './CoreLayout';

const mapStateToProps = (state) => ({
    propsStaff: state.staff.staff
});

export default connect(mapStateToProps, {...actions, selectScreenOnMain})(CoreLayout);
