import React, {useEffect, useState} from 'react'

import {Link, useLocation} from 'react-router-dom';
import cn from 'classnames';

import Header from 'components/Header';
import Preloader from 'components/Preloader';

import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ru';

import './useful.module.scss';
import classes from './useful.module.scss';
import {API_URL} from "utils";

const Useful = ({fetchStaff, propsStaff, fetching}) => {
    const location = useLocation()
    const [viewByType, setViewByType] = useState('');

    useEffect(() => {
        fetchStaff(location.pathname + viewByType, {schemes: 'staff_prev_4-3,staff_prev_png,staff_a4'});
    }, [location, viewByType, fetchStaff]);

    let url = window.location.pathname + viewByType;
    let staff = _.get(propsStaff, url + '.items', []),
        catalogue = _.get(propsStaff, url + '.catalog', {});
    let allLinks = {
        'video': 'всё видео',
        'common': 'все статьи',
        'news': 'все новости'
    };

    return (
        <div className={classes.usefulComp}>
            <Header/>
            <div className={classes.wrapper}>
                <div className={classes.filter}>
                    <div className={cn({[classes.all]: true, active: viewByType === ''})}
                         onClick={() => setViewByType('')}>Смотреть все</div>
                    <div className={cn({[classes.news]: true, active: viewByType === '/news'})}
                         onClick={() => setViewByType('/news')}/>
                    <div className={cn({[classes.articles]: true, active: viewByType === '/articles'})}
                         onClick={() => setViewByType('/articles')}/>
                    <div className={cn({[classes.video]: true, active: viewByType === '/video'})}
                         onClick={() => setViewByType('/video')}/>
                </div>
                <div className={classes.description}>
                    <div className={classes.byCatalogue}>{catalogue.description}</div>
                    <div className={classes.content}>{catalogue.content}</div>
                </div>
                <div className={classes.items}>

                    {_.map(staff, item => {
                        return (
                            <div className={classes.item + ' ' + classes[item.catalog_type]}>
                                <div
                                    className={classes.date}>{moment(item.date_insert).locale('ru').format('DD MMMM Y')}</div>
                                <div className={classes.image}>
                                    <div className={classes.imageWrapper}>
                                        <img
                                            src={API_URL + _.get(_.find(item.file, {is_main: '1'}), (item.catalog_type === 'video' ? 'staff_prev_4-3' : 'staff_a4'))}
                                            alt={item.title}
                                        />
                                    </div>
                                </div>
                                <div className={classes.title}>{item.title}</div>
                                <Link className={classes.readon} to={'/useful/' + item.label + '-item'}/>
                                {item.second_title !== ''
                                    ?
                                    (<div className={classes.secondTitle}>{item.second_title}</div>)
                                    :
                                    (<div
                                        className={classes.secondTitle + ' ' + classes.links}>{allLinks[item.catalog_type]}</div>)
                                }


                            </div>);
                    })}
                </div>
                <Preloader trigger={_.some(fetching, (f) => {
                    return f;
                })}/>
            </div>
        </div>
    );
}

export default Useful

