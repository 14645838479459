import {api} from 'utils';

// ------------------------------------
// Constants
// ------------------------------------

const REQUEST_TYPES = 'requestTypes';
const RECEIVE_TYPES = 'receiveTypes';

const REQUEST_PIMPS = 'requestPimps';
const RECEIVE_PIMPS = 'receivePimps';

const RECEIVE_MARKS = 'receiveMarks';

const REQUEST_MODELS = 'requestModels';
const RECEIVE_MODELS = 'receiveModels';

const REQUEST_MODEL_NUMBERS = 'requestModelNumbers';
const RECEIVE_MODEL_NUMBERS = 'receiveModelNumbers';

const REQUEST_MODEL_GENERATIONS = 'requestModelGenerations';
const RECEIVE_MODEL_GENERATIONS = 'receiveModelGenerations';

const REQUEST_LAMPS = 'requestLamps';
const RECEIVE_LAMPS = 'receiveLamps';

const FETCHING = 'fetching';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchTypes = () => {
    return function (dispatch) {
        dispatch(requestTypes());
        return api('/fields/type',  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveTypes(res))
            });
    };
};

function requestTypes() {
    return {
        type: REQUEST_TYPES
    }
}

function receiveTypes(res) {
    return {
        type: RECEIVE_TYPES,
        payload: res
    }
}

export const fetchPimps = () => {
    return function (dispatch) {
        dispatch(requestPimps());
        return api('/fields/pimps',  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receivePimps(res))
            });
    };
};

function requestPimps() {
    return {
        type: REQUEST_PIMPS
    }
}

function receivePimps(res) {
    return {
        type: RECEIVE_PIMPS,
        payload: res
    }
}

function fetching() {
    return {
        type: FETCHING
    }
}

export const fetchCarMarks = () => {
    return function (dispatch) {
        dispatch(fetching());
        return api('/lamp_reference/car_marks',  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveMarks(res))
            });
    };
};

function receiveMarks(res) {
    return {
        type: RECEIVE_MARKS,
        payload: res
    }
}

export const fetchCarModels = (mark) => {
    return function (dispatch) {
        dispatch(requestModels());
        //return api('http://koitolamp.ru/catalog/children?slug=' + mark,  {
        return api('/lamp_reference/car_models/' + mark,  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveModels(mark, res))
            });
    };
};

function requestModels() {
    return {
        type: REQUEST_MODELS
    }
}

function receiveModels(mark, res) {
    return {
        type: RECEIVE_MODELS,
        mark: mark,
        payload: res
    }
}

export const fetchCarModelNumbers = (model) => {
    return function (dispatch) {
        dispatch(requestModelNumbers());
        //return api('http://koitolamp.ru/catalog/children?slug=' + mark,  {
        return api('/lamp_reference/car_model_numbers/' + model,  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveModelNumbers(model, res))
            });
    };
};

function requestModelNumbers() {
    return {
        type: REQUEST_MODEL_NUMBERS
    }
}

function receiveModelNumbers(model, res) {
    return {
        type: RECEIVE_MODEL_NUMBERS,
        model: model,
        payload: res
    }
}

export const fetchCarModelGenerations = (modelNumber) => {
    return function (dispatch) {
        dispatch(requestModelGenerations());
        //return api('http://koitolamp.ru/catalog/children?slug=' + mark,  {
        return api('/lamp_reference/car_model_generations/' + modelNumber,  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveModelGenerations(modelNumber, res))
            });
    };
};

function requestModelGenerations() {
    return {
        type: REQUEST_MODEL_GENERATIONS
    }
}

function receiveModelGenerations(modelNumber, res) {
    return {
        type: RECEIVE_MODEL_GENERATIONS,
        modelNumber: modelNumber,
        payload: res
    }
}

export const fetchLamps = (modelGeneration) => {
    return function (dispatch) {
        dispatch(requestLamps());
        return api('/lamp_reference/lamps/' + modelGeneration,  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveLamps(modelGeneration, res))
            });
    };
};

function requestLamps() {
    return {
        type: REQUEST_LAMPS
    }
}

function receiveLamps(modelGeneration, res) {
    return {
        type: RECEIVE_LAMPS,
        modelGeneration: modelGeneration,
        payload: res
    }
}

export const actions = {
    fetchCarMarks,
    fetchCarModels,
    fetchCarModelNumbers,
    fetchCarModelGenerations,
    fetchLamps,
    fetchTypes,
    fetchPimps
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [RECEIVE_TYPES]: (state, action) => {
        return ({ ...state, types: action.payload });
    },
    [RECEIVE_PIMPS]: (state, action) => {
        return ({ ...state, pimps: action.payload });
    },
    [RECEIVE_MARKS]: (state, action) => {
        return ({ ...state, marks: action.payload });
    },
    [RECEIVE_MODELS]: (state, action) => {
        let models = Object.assign({}, state.models);
        models[action.mark] = action.payload;
        return ({ ...state, models: models });
    },
    [RECEIVE_MODEL_NUMBERS]: (state, action) => {
        let modelNumbers = Object.assign({}, state.modelNumbers);
        modelNumbers[action.model] = action.payload;
        return ({ ...state, modelNumbers: modelNumbers });
    },
    [RECEIVE_MODEL_GENERATIONS]: (state, action) => {
        let modelGenerations = Object.assign({}, state.modelGenerations);
        modelGenerations[action.modelNumber] = action.payload;
        return ({ ...state, modelGenerations: modelGenerations });
    },
    [RECEIVE_LAMPS]: (state, action) => {
        let lamps = Object.assign({}, state.lamps);
        lamps[action.modelGeneration] = action.payload;
        return ({ ...state, lamps: lamps });
    }
    
};


// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    marks: [],
    models: {},
    modelNumbers: {},
    modelGenerations: {},
    lamps: {},
    types: [],
    pimps: []
};


export default function referenceReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
