import React from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash.get';
import classes from './header.module.scss';
import {ROUTES} from "routes";

class Header extends React.Component {

    state = {
        search: ''
    };

    componentDidMount() {

    }

    render() {
        const location = window.location;
        let url = location.pathname;
        let branch = [], catalogue = {};
        if (this.props.staff[url]) {
            //мы в каталоге
            branch = get(this.props.staff, url + '.branch', []);
            catalogue = get(this.props.staff, url + '.catalog', {});
        } else {
            if (this.props.detailedStaff[url]) {
                branch = get(this.props.detailedStaff, url + '.branch', []);
                catalogue = get(this.props.detailedStaff, url + '.catalog', {});
            }
        }

        branch = [{id: 1, path: '/', title: 'Главная'}].concat(branch);

        //подбор ламп
        if (url === ROUTES.selection) {
            branch = [
                {id: 1, path: ROUTES.main, title: 'Главная'},
                {id: 2, path: ROUTES.selection, title: 'Подбор ламп'}
            ];
            catalogue.title = 'Подбор ламп';
        }
        //поиск
        if (url === ROUTES.search) {
            branch = [
                {id: 1, path: ROUTES.main, title: 'Главная'},
                {id: 2, title: 'Поиск на сайте'}
            ];
            catalogue.title = 'Подбор ламп';
        }


        let branchLinks = [];
        branch.forEach((b,index) => {
            //TODO не выводить если нет роута
            if (b.path !== '/catalogue') {
                if (b.path) {
                    branchLinks.push(<Link key={b.id} to={b.path}>{b.title}</Link>);
                } else {
                    branchLinks.push(<div key={b.id}>{b.title}</div>);
                }
                if (branchLinks.length < branch.length) {
                    branchLinks.push(<div key={index} className="divider"/>);
                }
            }

        });

        return (
            <div className={classes.headerComp}>
                <div className={classes.breadcrumbs}>
                    {branchLinks}
                </div>
                <div className={classes.sectionTitle}>
                    {catalogue.title}
                </div>
{/*                <div className={classes.searchBlock}>
                    <input type="text" value={this.state.search} onChange={(e) => {
                        this.setState({search: e.target.value})
                    }}/>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        this.context.router.push('/search?search=' + this.state.search);
                    }}/>
                </div>*/}
            </div>
        )
    }
}

export default Header;
