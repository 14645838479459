import { connect } from 'react-redux';
import { actions } from 'redux/staff';

import Header from './Header';

const mapStateToProps = (state) => ({
    staff: state.staff.staff,
    detailedStaff: state.staff.detailedStaff
});

export default connect(mapStateToProps,  actions)(Header);