import { api, http_build_query } from 'utils';

// ------------------------------------
// Constants
// ------------------------------------

const REQUEST_CATALOGUE_TREE = 'requestCatalogueTree';
const RECEIVE_CATALOGUE_TREE = 'receiveCatalogueTree';

const REQUEST_STAFF = 'requestStaff';
const RECEIVE_STAFF = 'receiveStaff';

const REQUEST_STAFF_ITEM = 'requestStaffItem';
const RECEIVE_STAFF_ITEM = 'receiveStaffItem';

const REQUEST_CERTIFICATES = 'requestCertificates';
const RECEIVE_CERTIFICATES = 'receiveCertificates';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCatalogueTree = (tag) => {
    return function (dispatch) {
        dispatch(requestCatalogueTree())
        return api('/catalogue/tree/' + tag,  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveCatalogueTree(res, tag))
            });
    };
};

function requestCatalogueTree() {
    return {
        type: REQUEST_CATALOGUE_TREE
    }
}

function receiveCatalogueTree(res, tag) {
    return {
        type: RECEIVE_CATALOGUE_TREE,
        tag: tag,
        payload: res
    }
}

export const fetchStaffItem = (url, schemes) => {
    return function (dispatch) {
        dispatch(requestStaffItem())
        return api(url.replace('-item', '.html') + (schemes ? ('?schemes=' + schemes.join(',')) : ''),  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveStaffItem(res, url))
            });
    };
};

function requestStaffItem() {
    return {
        type: REQUEST_STAFF_ITEM
    }
}

function receiveStaffItem(res, url) {
    return {
        type: RECEIVE_STAFF_ITEM,
        url: url,
        payload: res
    }
}

export const fetchStaff = (url, params) => {
    return function (dispatch) {
        dispatch(requestStaff())
        return api(url + '?' + http_build_query(params),  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveStaff(res, url))
            });
    };
};

export const fetchSearchedStaff = (url, params) => {
    return function (dispatch) {
        dispatch(requestStaff())
        return api(url + '?' + http_build_query(params),  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveStaff(res, '/search'))
            });
    };
};

function requestStaff() {
    return {
        type: REQUEST_STAFF
    }
}

function receiveStaff(res, url) {
    return {
        type: RECEIVE_STAFF,
        url: url,
        payload: res
    }
}

export const fetchCertificates = () => {
    return function (dispatch) {
        dispatch(requestCertificates())
        return api('/certificates?schemes=staff_a4',  {
            method: 'GET'
        }, dispatch)
            .then(res => {
                return dispatch(receiveCertificates(res))
            });
    };
};

function requestCertificates() {
    return {
        type: REQUEST_CERTIFICATES
    }
}
function receiveCertificates(res) {
    return {
        type: RECEIVE_CERTIFICATES,
        payload: res
    }
}

export const actions = {
    fetchCertificates,
    fetchStaff,
    fetchSearchedStaff,
    fetchStaffItem,
    fetchCatalogueTree
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_CERTIFICATES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CERTIFICATES]: (state, action) => {
        return ({ ...state, certificates: action.payload });
    },
    [REQUEST_STAFF]: (state) => {
        let fetching = Object.assign({}, state.fetching);
        fetching.staff = true;
        return ({ ...state, fetching: fetching });
    },
    [RECEIVE_STAFF]: (state, action) => {
        let staff = Object.assign({}, state.staff);
        staff[action.url] = action.payload;
        let fetching = Object.assign({}, state.fetching);
        fetching.staff = false;
        return ({ ...state, staff: staff, fetching: fetching });
    },
    [REQUEST_STAFF_ITEM]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_STAFF_ITEM]: (state, action) => {
        let detailedStaff = Object.assign({}, state.detailedStaff);
        detailedStaff[action.url] = action.payload;
        return ({ ...state, detailedStaff: detailedStaff });
    },
    [REQUEST_CATALOGUE_TREE]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CATALOGUE_TREE]: (state, action) => {
        let catalogueTree = Object.assign({}, state.catalogueTree);
        catalogueTree[action.tag] = action.payload;
        return ({ ...state, catalogueTree: catalogueTree });
    }
};


// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    catalogue: {},
    branch: [],
    staff: {},
    detailedStaff: {},
    certificates: null,
    catalogueTree: {},
    fetching: {}
};

export default function accountReducer (state = initialState, action) {
    state = Object.assign({}, initialState, state);

    const handler = ACTION_HANDLERS[ action.type ];

    return handler ? handler(state, action) : state;
}
