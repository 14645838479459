import React from 'react'

require ('../Preloader/style.scss');

export const Preloader = (props) => (
    <div className={"preloader" + (props.trigger ? ' active' : '')}>
        <div className="circle"/>
    </div>
);

export default Preloader
