import { connect } from 'react-redux'
import { actions } from 'redux/staff';

import Contacts from './Contacts'

const mapStateToProps = (state) => ({
    staff: state.staff.staff
});

export default connect(mapStateToProps, Object.assign({}, actions))(Contacts)
