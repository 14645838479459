import { connect } from 'react-redux'
import { actions } from 'redux/staff';

import UsefulItem from './UsefulItem'

const mapStateToProps = (state) => ({
    staff: state.staff.detailedStaff,
    catalogueTree: state.staff.catalogueTree
});

export default connect(mapStateToProps, Object.assign({}, actions))(UsefulItem);
