import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import Header from 'components/Header';
import _ from 'lodash';
import "react-image-gallery/styles/css/image-gallery.css";
import './usefulitem.module.scss';
import {API_URL} from "utils";

const UsefulItem = ({fetchStaffItem, staff}) => {
    useEffect(() => {
        fetchStaffItem(window.location.pathname, ['rectangle_medium', 'staff_prev']);
    }, [fetchStaffItem]);

    let url = window.location.pathname + window.location.search;
    let item = _.get(staff, url + '.item', []);
    let galleryFiles = _.filter(item.files, {type: 'image'}),
        mainImage = _.find(item.files, {type: 'image', is_main: '1'});

    return (
        <div className="usefulCompItem">
            <Header location={window.location}/>
            <div className="usefulCompItem__staffItem">
                <div className="usefulCompItem__staffItem__wrapper">
                    <div className="usefulCompItem__backButton">
                        <Link to='/useful'><span>&larr;</span>Назад в рубрику Полезное</Link>
                    </div>
                    {
                        galleryFiles.length === 1
                            ? (<div className="mainImage"><img src={API_URL + mainImage.rectangle_medium}
                                                               alt={item.title}/></div>)
                            : null
                    }
                    {
                        galleryFiles.length > 1
                            ? (
                                <div className="usefulCompItem__gallery">
                                    <ImageGallery
                                        items={_.map(_.filter(item.files, {type: 'image'}), file => {
                                            return {
                                                original: API_URL + file.rectangle_medium,
                                                thumbnail: API_URL + file.staff_prev
                                            };
                                        })}
                                    />
                                </div>
                            )
                            : null
                    }

                    <div className="usefulCompItem__title">
                        {item.title}
                    </div>
                    <div className="usefulCompItem__description">
                        {item.description}
                    </div>
                    <div className="usefulCompItem__content" dangerouslySetInnerHTML={{__html: item.content}}/>
                </div>
            </div>
        </div>
    );
}

export default UsefulItem
