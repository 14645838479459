import React from 'react';
import cn from "classnames";
import classes from "./home.module.scss";

const MobileScreen = ({screenVariant}) => {
    return(
        <div className={cn({[classes.mobileScreen]: true, [classes[screenVariant]]: true})}>
            {screenVariant === 'first' ? <div className={classes.text}>LED Ближний и дальний</div> : null}
            {screenVariant === 'second' ? <div className={classes.text}>XENON<br/>Ближний и дальний</div> : null}
            {screenVariant === 'third' ? <div className={classes.text}>LED<br/>Габариты,<br/>подстветка салона</div> : null}
        </div>);
};

export default MobileScreen;
