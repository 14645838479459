import React from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import Home from 'Routes/Home';
import 'styles/styles.scss';
import {Route, Routes} from "react-router-dom";
import store from "redux/store";
import {ROUTES} from "routes";
import CoreLayout from "./layouts/CoreLayout";
import Contacts from "Routes/Contacts";
import CatalogueDirect from "Routes/CatalogueDirect";
import Common from "Routes/Common";
import UsefulDirect from "./Routes/UsefulDirect";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={store}>
    <BrowserRouter>
        <Routes>
            <Route element={<CoreLayout/>}>
                <Route path={ROUTES.main} element={<Home/>}/>
                <Route path={ROUTES.contacts} element={<Contacts/>}/>
                <Route path={ROUTES.catalogue} element={<CatalogueDirect/>}/>
                <Route path={ROUTES.geography} element={<Common/>}/>
                <Route path={ROUTES.aboutBrend} element={<Common/>}/>
                <Route path={ROUTES.useful} element={<UsefulDirect/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
</Provider>);